import {
  Component, OnInit, NgZone, AfterViewInit, OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AppService, AuthenticationService, LOGGEDINUSER, PromptDialogService, UIConstants, UpdateUserSession, User, UserService,
} from '@lc/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

function _window(): any {
  return window;
}

const BRAND_MAPPER = new Map([
  ['CBA', 'cb'],
  ['CBR', 'cb'],
  ['BHG', 'bhg'],
  ['ERA', 'era'],
]);
export class ViewModel {
  readonly firstName: string;
  readonly profilePhotoUrl: string;
  readonly showProfileLink: boolean = AppService.isCoordinatorApp || AppService.isAgentApp;
  readonly showFeedbackLink: boolean = AppService.isDCUserApp;
  readonly showWhatsNewLink: boolean = AppService.isCoordinatorApp;

  readonly showOnBehalfOf: boolean;

  constructor(readonly user: User, readonly assumedUser: User, isOBOEnabled: boolean) {
    this.firstName = user.getFirstName();
    this.profilePhotoUrl = user?.profile?.profilePhotoUrl || user?.profile?.photoUrl;

    this.showOnBehalfOf = isOBOEnabled;
  }
}

@Component({
  selector: 'lc-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly AppService = AppService;
  viewModel$: Observable<ViewModel>;
  productServicesTab = false;
  public mounted = false;
  public isSetMenu = false;
  profilePhoto: string;
  firstName: string;
  fullName:string;
  lastName:string;
  id: string;
  appkey: string;
  showProfileLink: boolean;
  showWhatsNewLink: boolean;
  showOnBehalfOf: boolean;
  showOnBehalfOf$: Observable<boolean>;
  showFeedbackLink: boolean;
  getAssumedUser: User;
  rscClient: any;
  oboUserAction$: Subscription;
  public brandKey:string;

  constructor(
    private readonly router: Router,
    private readonly store: Store<any>,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly dialogService: PromptDialogService,
    private ngZone: NgZone,
  ) {
    const user$ = this.store.select(LOGGEDINUSER).pipe(map((user: User) => user));
    const assumedUser$ = user$.pipe(map(() => (this.userService.isAssumedUser() ? this.userService.getCurrentUser() : null)));

    this.viewModel$ = combineLatest([user$, assumedUser$]).pipe(
      map(([user, assumedUser]) => new ViewModel(user, assumedUser, userService.isOBOEnabled())),
    );
    this.oboUserAction$ = this.userService.OBOUser.subscribe(
      (val) => {
        if (val) {
          this.removeRscMenuItems();
          this.setRscValues();
        }
      },
      (err) => { console.log(err); },
    );
  }

  ngOnInit(): void {
    this.ngZone.run(() => this.mounted = true);
    this.viewModel$.subscribe(
      (res) => {
        this.profilePhoto = res.profilePhotoUrl;
        this.firstName = res.user.firstName;
        this.fullName = res.user.lastName;
        this.id = res.user.profile._id;
        this.showProfileLink = res.showProfileLink;
        this.showWhatsNewLink = res.showWhatsNewLink;
        this.showOnBehalfOf = res.showOnBehalfOf;
        this.showFeedbackLink = res.showFeedbackLink;
        this.getAssumedUser = res.assumedUser;
        this.brandKey = this.getBrandKey(res);
      },
      (errData) => { console.log(errData); },
    );

    this.appkey = AppService.get('applicationName');
  }

  ngAfterViewInit() {
    this.setRscValues();
  }

  getBrandKey(res) {
    const brandCode = res.assumedUser?.profile?.office?.company?.brandCode ?? res.user?.profile?.office?.company?.brandCode;
    const brandKey = BRAND_MAPPER.get(brandCode) ?? 'cb';
    return brandKey;
  }

  async setRscValues() {
    const toolbarComponent = this;
    this.rscClient = _window().__RSC__;
    if (this.rscClient) {
      if (this.showProfileLink) {
        this.rscClient.setMenuItem({
          name: 'My Profile',
          icon: 'far fa-user',
          target: '_blank',
          callback: () => {
            this.router.navigate([`/my-profile/${this.id}`]);
          },
        });
      }
      if (this.showWhatsNewLink) {
        this.rscClient.setMenuItem({
          name: "What's New",
          icon: 'fa-solid fa-lightbulb-on',
          target: '_blank',
          callback: () => {
            this.router.navigate(['/whats-new']);
          },
        });
      }
      if (this.showOnBehalfOf && !this.getAssumedUser) {
        this.rscClient.setMenuItem({
          name: 'On Behalf of', url: '/on-behalf-of', icon: 'fal fa-user-group', target: '_blank',
        });
      }
      if (this.showOnBehalfOf && this.getAssumedUser) {
        this.rscClient.removeMenuItem('On Behalf of');
      }
      if (this.showFeedbackLink) {
        this.rscClient.setMenuItem({
          name: 'Feedback', url: '/feedback', icon: 'fa-solid fa-message-exclamation', target: '_blank',
        });
      }
      this.rscClient.setMenuItem({
        name: 'Log Out',
        icon: 'fal fa-sign-out-alt',
        target: '_blank',
        callback: () => {
          toolbarComponent.onLogout();
        },
      });
      this.rscClient.setUserFirstName(this.firstName);
      if (this.profilePhoto) {
        this.rscClient.setUserPhoto(this.profilePhoto);
      }

      this.rscClient.setUsername(`${this.firstName} ${this.fullName}`);
      this.rscClient.showCaretDownIconInTopnav(true);
      const accessTokenValue = JSON.parse(localStorage.getItem('lc-token-storage'));
      this.rscClient.setAccessToken(accessTokenValue?.accessToken?.value);
      this.rscClient.showWaffleMenu(false);
      this.rscClient.setHomeButtonCallback(() => {
        this.router.navigate(['/']);
      });
    }
  }
  removeRscMenuItems() {
    this.rscClient.removeMenuItem('My Profile');
    this.rscClient.removeMenuItem('On Behalf of');
    this.rscClient.removeMenuItem('Log Out');
    this.rscClient.removeMenuItem('Feedback');
    this.rscClient.removeMenuItem("What's New");
  }

  async endImpersonation() {
    await this.userService.endAssume();
    this.router.navigate(['/on-behalf-of']).then(() => {
      this.router.navigate(['/']);
    });
  }

  /** Prompts the user if they want to logout. If confirmed, stops the expiration countdown and redirects to logout. */
  async onLogout() {
    const response = await this.dialogService.openPrompt('Confirm', 'Are you sure you want to log out?', UIConstants.SIGN_OUT, [UIConstants.NO], { centered: true });
    if (response?.text !== UIConstants.SIGN_OUT) { return; }

    setTimeout(() => {
      this.closeSessionExpiryCountDown();
      this.authService.redirectToLogout();
    }, 300);
  }

  private closeSessionExpiryCountDown() {
    const sessionUpdate = { expiryAt: new Date().getTime(), sessionGoingToExpiry: false };
    this.store.dispatch(UpdateUserSession({ payload: sessionUpdate }));
  }

  ngOnDestroy(): void {
    this.oboUserAction$?.unsubscribe();
  }
}
